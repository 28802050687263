import 'twin.macro';

import { navigate } from '@reach/router';
import IconFile from 'assets/svg/icons/file.inline.svg';
import ContentFit from 'components/Atoms/ContentFit';
import Comment from 'components/Organisms/Comment';
import PanelLimit from 'components/Organisms/PanelLimit';
import PanelSelect from 'components/Organisms/PanelSelect';
import SubHeader from 'components/Organisms/SubHeader';
import { routes } from 'constants/routes';
import HeaderContext from 'providers/HeaderProvider';
import React, { useContext, useEffect, useRef } from 'react';

export default function conclusion() {
	const { setHeader } = useContext(HeaderContext);
	const refContainer = useRef();

	useEffect(() => {
		setHeader({ isMain: false, isSelect: false, isLimit: false });
	}, []);

	return (
		<div ref={refContainer} className='flex flex-col items-center justify-between'>
			<SubHeader Icon={IconFile} title='Wniosek faktoringowy' iconSize='1.5rem' />

			<ContentFit siblingHeight='4.5rem'>
				<div className='panel-wrapper px-20 flex-4/1'>
					<PanelSelect onClick={() => navigate(routes.WELCOME_SELECT_ADVANCED_FORM)} />
					<PanelLimit onClick={() => navigate(routes.WELCOME_LIMIT_ADVANCED_FORM)} />
				</div>

				<Comment className='mb-10' secondary title='Przygotowanie wniosku zajmie 2-5 minut' withBg />
			</ContentFit>
		</div>
	);
}
